import React, { useRef, useState } from "react";
import linkdin from "../../img/share/linkedin_icon.png";
import reddit from "../../img/share/reddit_icon.png";
import whatsapp from "../../img/share/whatsapp_icon.png";
import twitter from "../../img/share/x_icon.png";
import facebook from "../../img/share/fb_icon.png";
import close_icon from "../../img/share/close_icon.svg";
import email from "../../img/share/email_icon.png";

import copy from "../../img/share/copy.svg";
import okicon from "../../img/chat/okicon.svg";

import { useShareWindow } from "../../context/ShareWindowContext";

import "./ShareWindow.css";

const ShareWindow = ({ generatedLink, onClose }) => {
  const knightingalelink = 'https://ask.knightingale.ai/';
  const linkInputRef = useRef(null);
  const [showOriginal, setShowOriginal] = useState(true);
  const { backgroundText, isButtonDisabled
  } = useShareWindow();
  const changeImage = () => { };

  const copyToClipboard = () => {
    if (linkInputRef.current) {
      linkInputRef.current.select();
      document.execCommand("copy");
    }
    setShowOriginal(false);

    setTimeout(() => {
      setShowOriginal(true);
    }, 1500);
  };

  console.log(generatedLink);
  const shareLink = "https://gggggg.as/kn-ar/?room=zippy_pig_817";
  const textShare = "Knightigale";

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      generatedLink
    )}&text=${encodeURIComponent("ask.knightingale.ai")}`;
    window.open(twitterUrl, "_blank", "width=600,height=300");
  };

  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      generatedLink
    )}&title=${encodeURIComponent("ask.knightingale.ai")}`;
    window.open(facebookUrl, "_blank", "width=600,height=400");
  };

  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      generatedLink
    )}&title=${encodeURIComponent("ask.knightingale.ai")}`;
    window.open(whatsappUrl, "_blank", "width=400,height=600");
  };

  const shareOnLinkedIn = () => {
    const linkedInUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
      generatedLink
    )}&title=${encodeURIComponent("ask.knightingale.ai")}`;
    window.open(linkedInUrl, "_blank", "width=600,height=400");
  };

  const shareOnReddit = () => {
    const redditUrl = `https://www.reddit.com/submit?url=${encodeURIComponent(
      generatedLink
    )}&title=${encodeURIComponent("ask.knightingale.ai")}`;
    window.open(redditUrl, "_blank", "width=600,height=400");
  };

  const shareViaEmail = () => {
    const emailSubject = encodeURIComponent("ask.knightingale.ai");
    const emailBody = encodeURIComponent(`${generatedLink}`);
    const emailUrl = `mailto:?subject=${emailSubject}&body=${emailBody}`;
    window.location.href = emailUrl;
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const handleMouseEnterCopy = () => {
    const timeout = setTimeout(() => {
      setShowTooltip(true);
    }, 2000); // 2 seconds
    setHoverTimeout(timeout);
  };

  const handleMouseLeaveCopy = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
  };
  return (
    <div className="modal-overlay">
      <div className="share-modal">
        <div className="share-close">
          <div className="share-title">Share {backgroundText} </div>
          <button className="close-button" onClick={onClose}>
            <img src={close_icon} alt="Twitter" className="" />
          </button>
        </div>
        <div className="share-option-wrpper">
          <div className="share-option-container">
            <div className="share-option-inner"></div>
          </div>
        </div>
        <div className="media-wrapper">
          <div className="media-container">
            <div className="media-inner">
              <div onClick={shareOnTwitter} className="media-inner-img">
                <img src={twitter} alt="Twitter" className="h-10" />
              </div>
              <div className="social-media-title">Twitter</div>
            </div>
            <div className="media-inner">
              <div onClick={shareOnFacebook} className="media-inner-img">
                <img src={facebook} alt="Facebook" className="h-10" />
              </div>

              <div className="social-media-title">Facebook</div>
            </div>
            <div className="media-inner">
              <div onClick={shareOnLinkedIn} className="media-inner-img">
                <img src={linkdin} alt="Linkdin" className="h-10" />
              </div>

              <div className="social-media-title">Linkdin</div>
            </div>
          </div>
          <div className="media-container">
            <div className="media-inner">
              <div onClick={shareOnReddit} className="media-inner-img">
                <img src={reddit} alt="Discord" className="h-10" />
              </div>

              <div className="social-media-title">Reddit</div>
            </div>
            <div className="media-inner">
              <div onClick={shareOnWhatsApp} className="media-inner-img">
                <img src={whatsapp} alt="Whatsapp" className="h-10" />
              </div>
              <div className="social-media-title">Whatsapp</div>
            </div>
            <div className="media-inner">
              <div onClick={shareViaEmail} className="media-inner-img">
                <img src={email} alt="Email" className="h-10" />
              </div>
              <div className="social-media-title">Email</div>
            </div>
          </div>
        </div>
        <div className="copy-input">
          <input
            type="text"
            value={isButtonDisabled ? generatedLink : generatedLink}
            ref={linkInputRef}
            readOnly
          />
          <button className="copy-link" onClick={copyToClipboard}>
          
            <div
              style={{ position: "relative", width: "20px", height: "20px" }}
              className="copy-img copytool"
              onMouseEnter={handleMouseEnterCopy}
              onMouseLeave={handleMouseLeaveCopy}
            >
              <img
                src={showOriginal ? copy : okicon}
                alt={showOriginal ? "Original Image" : "Changed Image"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  // padding: "9px",
                  top: 0,
                  left: 0,
                  opacity: showOriginal ? 1 : 0,
                  transition: "opacity .5s ease",
                }}
              />
              {!showOriginal && (
                <img
                  src={okicon}
                  alt="copy"
                  style={{
                    width:' 20px',
                    objectFit: "cover",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    display: "block",
                    opacity: showOriginal ? 0 : 1,
                    transition: "opacity .75s ease",
                  }}
                />
              )}

              
              {showTooltip && <div style={{ color: '#000', }}>
                <div class="">
                  <span class="copytooltext">Copy</span>
                </div>
              </div>}
            </div>
            <div className="copy-text">
                Copy Link
              </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareWindow;
