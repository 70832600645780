import React, { useEffect, useState } from "react";

import recordIcon from "../../img/mic_recording_icon.svg";
import voiceLogo from "../../img/mic_icon.png";
import msgSend from "../../img/Send_icon.png";
import attach_icon from "../../img/chat/image_upload_icon.svg";
import upload_done from "../../img/chat/upload_done_icon.svg";
import { useHistory } from 'react-router-dom';
import "../ChatInput/ChatInput.css";
import close_icon from "../../img/chat/close_icon.png";
import { useShareWindow } from "../../context/ShareWindowContext.js";


const ChatInput = ({
  textareaRef,
  input,
  setInput,
  handleInputChange,
  getLetterCount,
  handleKeyDown,
  handleKeyUp,
  isSubmitting,
  disableMicButton,
  getPlaceholderText,
  suggestions,
  showDropdown,
  handleSuggestionClick,
  disableSendButton,
  handleMicButtonClick,
  isVoiceRecognitionActive,
  dropdownRef,
  getStyles,
  isRecording,
  handleImageChange,
  inputFileRef,
  handleImageUpload,
  selectedImage,
  imagePreview,
  setImagePreview,
  setSelectedImage,
  userHistory,
  selectedOption,
  setReturnBar,
  boxSuggession,
  setCenterHome
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getSubmit = () => {
    if (windowWidth < 767) {
      const boxShadow = input === "" ? "none" : "";
      const display = "block";
      return { boxShadow, display };
    } else {
      const boxShadow = input === "" ? "none" : "";
      const display = input === "" ? "none" : "block";
      return { boxShadow, display };
    }
  };

  const getVoice = () => {
    if (windowWidth < 767) {
      const display = "none";
      return { display };
    } else {
      const display = input === "" ? "block" : "none";
      return { display };
    }
  };
  const getText = () => {
    const background = input === "" ? "" : "#fff";
    return { background };
  };


  const suggessionBorderColor = {
    borderColor: boxSuggession,
  };
  const { setIsButtonDisabled, setTextCount, minMaxHeight, setShowShareWindow, imagePreviews, setImagePreviews, isStyled, setIsStyled } = useShareWindow();



  // window.addEventListener('popstate', (event) => {
  //   alert("You message");
  // });
  // console.log(getLetterCount())

  const getColor = () => {
    return getLetterCount() <= 2000 ? 'green' : 'red';
  };

  // // State to manage the typing text input value
  // const [typingText, setTypingText] = useState('');

  // // useEffect to retrieve stored text from localStorage on component mount
  // useEffect(() => {
  //   const storedText = localStorage.getItem('typedText');
  //   if (storedText) {
  //     setTypingText(storedText);
  //     setInput(storedText)
  //     console.log("retrived " + storedText)
  //   }
  // }, []);

  // // useEffect to update localStorage whenever the typing text changes
  // useEffect(() => {
  //   localStorage.setItem('typedText', input);
  // }, [input]);

  // // Function to handle typing text change
  // const handleTypingTextChange = (event) => {
  //   setTypingText(event.target.value);
  // };



  // const handleClearTypingText = () => {
  //   setTypingText('');
  //   localStorage.removeItem('typedText');
  // };


  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const handleMouseEnterSend = () => {
    if (window.innerWidth > 767) {

    const timeout = setTimeout(() => {
      setShowTooltip(true);
    }, 2000); // 2 seconds
    setHoverTimeout(timeout);
  }}

  const handleMouseLeaveSend = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
  };


  const [showTooltipMic, setShowTooltipMic] = useState(false);
  // const [hoverTimeout, setHoverTimeout] = useState(null);


  const handleMouseEnterMic = () => {
    if (window.innerWidth > 767) {
      const timeout = setTimeout(() => {
        setShowTooltipMic(true);
      }, 2000); // 2 seconds
      setHoverTimeout(timeout);
    };
  }


  const handleMouseLeaveMic = () => {
    clearTimeout(hoverTimeout);
    setShowTooltipMic(false);
  };


  function formatCharacterCount(count) {
    return count.toString().padStart(4, '0');
  }
  const textCount = input.replace(/\s+/g, '').length;
  const formattedCount = formatCharacterCount(textCount);

  const [truncatedText, setTruncatedText] = useState('');
  let maxChars;

  if (window.innerWidth > 767) {
    maxChars = 80;
  } else {
    maxChars = 20;
  }


  useEffect(() => {

    if (selectedImage && selectedImage.length > maxChars) {
      setTruncatedText(selectedImage.substring(0, maxChars) + '...');
      console.log(selectedImage.substring(0, maxChars) + '...')

    } else {
      setTruncatedText(selectedImage);
    }
  }, [selectedImage, maxChars]);





  const handleStoreButtonClick = () => {
    // Get the index of the last entry
    const index = userHistory.length;

    // Example: Get the image preview value from state or props
    const imagePreviewValue = imagePreview; // Replace with your actual method of getting the image preview value

    // Retrieve the existing stored values from local storage
    const storedImagePreviews = JSON.parse(localStorage.getItem("storedImagePreviews")) || {};

    // Store the image preview value using the index as the ID
    storedImagePreviews[index] = imagePreviewValue;

    // Store the updated values back to local storage
    localStorage.setItem("storedImagePreviews", JSON.stringify(storedImagePreviews));

    // Optionally, you can log a message to indicate that the values are stored
    console.log("Image preview value stored in local storage with index as ID.");


  };


  // const handleRetrieveButtonClick = () => {
  //   // Retrieve the stored image previews from local storage
  //   const storedImagePreviews = JSON.parse(localStorage.getItem("storedImagePreviews"));

  //   // Check if there are stored image previews
  //   if (storedImagePreviews) {
  //     // Iterate over the stored image previews object
  //     for (const index in storedImagePreviews) {
  //       // Retrieve the preview value for each index
  //       const imagePreviewValue = storedImagePreviews[index];
  //       console.log(`Index: ${index}, Preview Value: ${imagePreviewValue}`);
  //     }
  //   } else {
  //     console.log("No stored image previews found.");
  //   }
  // };


  const handleRetrieveButtonClick = () => {
    // Retrieve the stored image previews from local storage
    const storedImagePreviews = JSON.parse(localStorage.getItem("storedImagePreviews"));

    // Check if there are stored image previews
    if (storedImagePreviews) {
      setImagePreviews(storedImagePreviews);
      // setImagePreview(null);

    } else {
      console.log("No stored image previews found.");
    }

  };

  useEffect(() => {

    setImagePreview(null);


  }, [userHistory]);


  window.addEventListener('beforeunload', () => {
    localStorage.removeItem("storedImagePreviews");
  });

  return (
    <div className={`input-container   ${!isStyled ? 'start-input-container' : 'input-container-background'}`}>

      <div className="input-container-wrapper" style={{ ...getText(), ...suggessionBorderColor }}>
        {selectedImage && (
          <div className="select_img_wrapper">
            <div className="select_img_inner">

              {imagePreview && (
                <>
                  <div className="preview_detai_wrapper">

                    <div className="preview_details">
                      <img
                        src={imagePreview}
                        alt="Selected Preview"
                        style={{ maxWidth: '100%', maxHeight: '80px' }}
                      />
                      <div className="preview_title" >{truncatedText}</div>
                    </div>

                    <div className="image_preview_close" style={{ color: '#000' }} onClick={() => { setSelectedImage(null); setImagePreview(null); }}>

                      <img className="image_preview_close_img" src={close_icon} alt="close_icon" />

                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <div className="input-container-inner">
          <textarea
            id="typedText"
            ref={textareaRef}
            className="kps-input-box"
            type="text"
            placeholder={!isRecording && getPlaceholderText()}
            value={input}
            onChange={(e) => {
              handleInputChange(e);

            }}

            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            // disabled={isSubmitting || disableMicButton}
            required
          />


          {
          input && suggestions.length > 0 && showDropdown && (
            <ul className="autocomplete-dropdown" ref={dropdownRef}>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="autocomplete-item"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          {(selectedOption === "option3" || selectedOption === "option4") && <div className="upload-img-wrapper">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              ref={inputFileRef}
            />
            <button type="button" onClick={handleImageUpload} className="comman-button-text-input image-upload">
              {!selectedImage && (<img src={attach_icon} alt="Upload" className="comman-button-text-input-img " />)}
              {selectedImage && (<img src={upload_done} alt="Upload" className="comman-button-text-input-img " />)}


            </button>

          </div>}
          {/* <button onClick={handleRetrieveButtonClick}>Retrieve Image Previews</button> */}

          <div className="submit-wrapper">
            <div className="submit-voice">
              <span style={getSubmit()}>
                <button
                  type="submit"
                  disabled={disableSendButton}
                  className="submit-button comman-button-text-input sendtool"
                  onClick={() => {
                    setCenterHome(false);
                    setSelectedImage(null);
                  }}

                  onMouseEnter={handleMouseEnterSend}
                  onMouseLeave={handleMouseLeaveSend}
                >
                  <img
                    onClick={() => {
                      handleStoreButtonClick(); handleRetrieveButtonClick();


                    }}

                    className="comman-button-text-input-img"
                    src={msgSend}
                    alt="Chat"
                    disabled={disableSendButton || isSubmitting}
                  />
                  {showTooltip && <div style={{ color: '#000', }}>
                    <div class="">
                      <span class="sendtooltext" style={{ marginLeft: '28px' }}>Submit</span>
                    </div>
                  </div>}

                </button>
              </span>
              {
                isRecording &&
                <div className="listening-wrapper">Listening</div>

              }
              <span style={getVoice()}>
                <button
                  className="submit-button ripple-btn comman-button-text-input sendtool"
                  type="button"
                  onClick={handleMicButtonClick}
                  disabled={disableMicButton}
                  onMouseEnter={handleMouseEnterMic}
                  onMouseLeave={handleMouseLeaveMic}
                >
                  {isRecording ? (
                    <img
                      className="comman-button-text-input-img chat-logo-record"
                      src={recordIcon}
                      alt="Record"
                    />
                  ) : (
                    <img
                      className="comman-button-text-input-img chat-logo-voice"
                      src={voiceLogo}
                      alt="Microphone"
                    />

                    // <div>
                    // <animated.div
                    // className="comman-button-text-input-img chat-logo-record"
                    //   style={{
                    //     width: '8px',
                    //     height: '8px',
                    //     backgroundColor: 'red',
                    //     transform: animationProps.scale.interpolate((s) => `scale(${s})`),
                    //   }}
                    // />
                    // </div>
                  )}
                  {showTooltipMic && <div style={{ color: '#000', }}>
                    <div class="">
                      <span class="sendtooltext">Use microphone</span>
                    </div>
                  </div>}
                </button>
              </span>

            </div>
          </div>
        </div>
        {input && <div className="count-wrapper">
          <div className="count-container"
            style={{
              color: textCount === 0 ? '#aaaeaa' : (textCount < 2000 ? 'green' : 'red')
            }}

          >
            {formattedCount}
          </div>


        </div>}

      </div>

    </div>
  );
};

export default ChatInput;
