import React, { useEffect, useRef, useState } from "react";

import stop from "../../img/versionthree/stop_button.png";
import loadingModel from "../../img/knightingale_typing.png";
import play from "../../img/versionthree/listen_button.png";
import copy from "../../img/chat/copy.svg";
import share_line from "../../img/chat/share_line.svg";
import okicon from "../../img/chat/okicon.svg";
import pose_1 from "../../img/chat/heart.svg";
import pose_2 from "../../img/chat/pose_2.jpg";
import pose_3 from "../../img/chat/pose_3.jpg";
import pose_4 from "../../img/chat/pose_4.jpg";
import pose_5 from "../../img/chat/pose_5.jpg";
import pose_6 from "../../img/chat/pose_6.jpg";
import pose_7 from "../../img/chat/pose_7.jpg";
import dot_menu from "../../img/chat/dot_menu.png";

import PlayLoading from "../../img/chat/playloading.js";
import { useShareWindow } from "../../context/ShareWindowContext.js";
import close_icon from "../../img/share/close_icon.svg";


import "../Chat/Chat.css";

const Chat = ({
  userHistory,
  currentlyPlayingIndex,
  isVoiceSynthesisInProgress,
  handleStopButtonClick,
  playAudio,
  showLoadingIndicator,
  handlechtst1ButtonClick,
  setInput,
  responseTexts,
  imgSuggession,
  suggessionTitle,
  selectedOption,
  boxSuggession,
}) => {
  const outputContainerRef = useRef(null);
  const outputinnerContainerRef = useRef(null);
  const [typingLoaded, setTypingLLoaded] = useState(false);
  const [dotMenu, setDotMenu] = useState(false);
  const [showOriginal, setShowOriginal] = useState(true);
  const { setIsButtonDisabled, textCount, setTextCount, minMaxHeight, setShowShareWindow, imagePreviews, setImagePreviews, input, isStyled, setAudioVideoChange, setIsStyled } = useShareWindow();
  const [imageUrl, setImageUrl] = useState(null);
  const [imageZoom, setImageZoom] = useState(false);
  const [imageUrlZoom, setImageUrlZoom] = useState();
  const images = [pose_1];
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const buttonRefs = useRef([]);
  const menuRef = useRef(null);
  const [containerHeightStyle, setContainerHeightStyle] = useState({});

  for (let i = 0; i < imagePreviews.length; i++) {
    console.log("Index:", i, "Value:", imagePreviews[i]);
  }


  // const containerHeightStyle = {
  //   minHeight: minMaxHeight === 0 ? `${window.innerHeight - minMaxHeight - 190}px` : `${window.innerHeight - minMaxHeight - 180}px`,
  //   maxHeight: minMaxHeight === 0 ? `${window.innerHeight - minMaxHeight - 190}px` : `${window.innerHeight - minMaxHeight - 180}px`
  // };

  useEffect(() => {
    console.log("testes")
    const newHeightStyle = {
      minHeight: minMaxHeight === 0 ? (input ? `${window.innerHeight - minMaxHeight - 130}px` : `${window.innerHeight - minMaxHeight - 120}px`) : (input ? (window.innerWidth < 767 ? `${window.innerHeight - minMaxHeight - 190}px` : `${window.innerHeight - minMaxHeight - 160}px`) : (window.innerWidth < 767 ? `${window.innerHeight - minMaxHeight - 180}px` : `${window.innerHeight - minMaxHeight - 140}px`)),
      maxHeight: minMaxHeight === 0 ? (input ? `${window.innerHeight - minMaxHeight - 130}px` : `${window.innerHeight - minMaxHeight - 120}px`) : (input ? (window.innerWidth < 767 ? `${window.innerHeight - minMaxHeight - 190}px` : `${window.innerHeight - minMaxHeight - 160}px`) : (window.innerWidth < 767 ? `${window.innerHeight - minMaxHeight - 180}px` : `${window.innerHeight - minMaxHeight - 140}px`))
    };
    setContainerHeightStyle(newHeightStyle);
  }, [input]);




  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setImageUrl(images[randomIndex]);
  }, []);



  const handleTextChange = () => {
    const answerElement = document.getElementById("answer");

    if (answerElement) {
      const text = answerElement.innerText;
      const count = text.length;
      console.log(count)
      setTextCount(count);
      setIsButtonDisabled(count === 0);
    }
  };

  useEffect(() => {
    handleTextChange();

    document.addEventListener("DOMSubtreeModified", handleTextChange);

    return () => {
      document.removeEventListener("DOMSubtreeModified", handleTextChange);
    };
  }, []);

  // Use state to store an array of refs
  const [textRefs, setTextRefs] = useState([]);

  // This effect will initialize the refs when the component mounts or userHistory changes
  useEffect(() => {
    setTextRefs((prevTextRefs) =>
      userHistory.map((_, index) => prevTextRefs[index] || React.createRef())
    );
  }, [userHistory]);

  // This effect will log the refs for demonstration purposes
  useEffect(() => {
    console.log(textRefs);
  }, [textRefs]);

  // const handleCopyClick = (textRef) => () => {
  //   if (textRef && textRef.current) {
  //     const textToCopy = textRef.current.innerText;

  //     // Create a textarea element, set its value, and append it to the document
  //     const textarea = document.createElement("textarea");
  //     textarea.value = textToCopy;
  //     document.body.appendChild(textarea);

  //     // Select and copy the text
  //     textarea.select();
  //     document.execCommand("copy");

  //     // Remove the textarea from the document
  //     document.body.removeChild(textarea);

  //     console.log("Text copied to clipboard");
  //     // You can add any additional logic or feedback here
  //   }
  //   setShowOriginal(false);

  //   setTimeout(() => {
  //     setShowOriginal(true);
  //   }, 2000);
  // };

  const handleCopyClick = (textRef, setShowOriginal) => () => {
    if (textRef && textRef.current) {
      const textToCopy = textRef.current.innerText;

      // Create a textarea element, set its value, and append it to the document
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);

      // Select and copy the text
      textarea.select();
      document.execCommand("copy");

      // Remove the textarea from the document
      document.body.removeChild(textarea);

      // Update the showOriginal state

      console.log("Text copied to clipboard");
      // You can add any additional logic or feedback here

      // Set showOriginal to true after 2000 milliseconds
      setTimeout(() => {
        setIsOk("");
      }, 2000);
    }
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
  };

  const [isOk, setIsOk] = useState();
  const [isMenuOk, setIsMenuOk] = useState();

  const clickOK = (index) => {
    console.log(index);
    setIsOk(index);
  };

  const menuClickOK = (index) => {
    console.log(index);
    setIsMenuOk(index);
  };

  useEffect(() => {
    scrollToBottom();
    setTypingLLoaded(true);
  }, [userHistory, showLoadingIndicator]);

  const scrollToBottom = () => {
    const parentElement = document.querySelector(".q-and-a-container");
    const outputContainer = outputContainerRef.current;
    const outputInner = outputinnerContainerRef.current;

    if (outputContainer) {
      const lastChild = outputContainer.lastChild;
      const childElements = parentElement.querySelectorAll(".main-q-a-box");

      if (lastChild && childElements.length > 0) {
        const containerHeight = outputContainer.clientHeight;
        const lastChildHeight = lastChild.clientHeight;
        const scrollHeight = outputContainer.scrollHeight;
        const innerHeight = outputInner.clientHeight;
        const lastChilds = childElements[childElements.length - 1];
        const lastChildHei = lastChilds.getBoundingClientRect().height;
        if (lastChildHei > containerHeight - 45) {
          outputContainer.scrollTop =
            scrollHeight -
            (containerHeight + 80) -
            (lastChildHei - (containerHeight - 50));
        } else {
          if (scrollHeight - containerHeight <= lastChildHeight) {
            outputContainer.scrollTop = scrollHeight - (containerHeight - 50);
          } else {
            lastChild.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }
      }
    }
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getHeight = () => {
    if (screenWidth <= 767) {
      return `${screenHeight * 0.5}px`;
    } else {
      return `${screenHeight * 0.42}px`;
    }
  };

  const suggessionBorderColor = {
    borderColor: boxSuggession,
  };


  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleMouseEnterCopy = (index) => {
    setHoveredIndex(index);
    const timeout = setTimeout(() => {
      setShowTooltip(true);
    }, 2000); // 2 seconds
    setHoverTimeout(timeout);
  };

  const handleMouseLeaveCopy = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
  };


  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRefs.current.every(buttonRef => !buttonRef.contains(event.target))
      ) {
        setOpenMenuIndex(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRefs]);

  const handleButtonClick = index => {
    setOpenMenuIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const addDotMenuRef = index => element => {
    buttonRefs.current[index] = element;
  };



  return (
    <>
      <div className={`q-and-a-container-wrapper    ${!isStyled ? 'start-q-and-a-container' : ''}`}>
        <div
          style={containerHeightStyle}
          className={`output-area   scrollbar ${!isStyled ? 'start-output-area' : ''}`}
          id="scrollbar2"
          ref={outputContainerRef}
        >
          <pre className="q-and-a-container" ref={outputinnerContainerRef}>
            {selectedOption !== "option1" && (
              <div className="main-q-a-box">
                <div
                  className="chat-suggesst-wrapper"
                  style={{ height: getHeight() }}
                >
                  <div className="chat-suggesst-title">
                    <div className="chat-suggesst-title-inner  ">
                      {/* <div style={{ height: "50px" }}></div> */}
                      {imageUrl && <img
                        className="chat-suggesst-img"
                        src={imageUrl}
                        alt=""
                      />}
                      <div className="chat-suggesst-title-text">
                        {suggessionTitle}
                      </div>
                    </div>
                  </div>
                  <div className={`chat-starters  ${!isStyled ? 'start-suggession-starter' : ''}`}>
                    <div className="suggetion-wrapper">
                      <div className="suggetion-container">
                        {" "}
                        <button
                          onClick={() => {
                            handlechtst1ButtonClick();
                            setInput(responseTexts[0]);
                          }}
                          style={suggessionBorderColor}
                        >
                          <div className="suggetion-icon">
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.000000px"
                              height="20.000000px"
                              viewBox="0 0 32.000000 32.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <g
                                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                                stroke="none"
                              >
                                <path
                                  className="colored-path"
                                  fill={boxSuggession}
                                  d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="response-wrapper">
                            {responseTexts[0]}
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="suggetion-wrapper">
                      <div className="suggetion-container">
                        {" "}
                        <button
                          onClick={() => {
                            handlechtst1ButtonClick();
                            setInput(responseTexts[1]);
                          }}
                          style={suggessionBorderColor}
                        >
                          <div className="suggetion-icon">
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.000000px"
                              height="20.000000px"
                              viewBox="0 0 32.000000 32.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <g
                                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                                stroke="none"
                              >
                                <path
                                  className="colored-path"
                                  fill={boxSuggession}
                                  d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="response-wrapper">
                            {responseTexts[1]}
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="suggetion-wrapper">
                      <div className="suggetion-container">
                        {" "}
                        <button
                          onClick={() => {
                            handlechtst1ButtonClick();
                            setInput(responseTexts[2]);
                          }}
                          style={suggessionBorderColor}
                        >
                          <div className="suggetion-icon">
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.000000px"
                              height="20.000000px"
                              viewBox="0 0 32.000000 32.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <g
                                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                                stroke="none"
                              >
                                <path
                                  className="colored-path"
                                  fill={boxSuggession}
                                  d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="response-wrapper">
                            {responseTexts[2]}
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="suggetion-wrapper">
                      <div className="suggetion-container">
                        {" "}
                        <button
                          onClick={() => {
                            handlechtst1ButtonClick();
                            setInput(responseTexts[3]);
                          }}
                          style={suggessionBorderColor}
                        >
                          <div className="suggetion-icon">
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.000000px"
                              height="20.000000px"
                              viewBox="0 0 32.000000 32.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <g
                                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                                stroke="none"
                              >
                                <path
                                  className="colored-path"
                                  fill={boxSuggession}
                                  d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="response-wrapper">
                            {responseTexts[3]}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userHistory.map((entry, index) => (
              <div className="main-q-a-box" key={index}>
                <div className="client-msg-bubble">
                  <div className="client-questions">
                    <div className="client_questin_text" style={{ width: '100%' }}>

                      <h2>
                        {entry.input}
                      </h2>
                    </div>
                    {imagePreviews[index] != null && <div style={{ maxWidth: '70ch', width: '100%', height: '200px', overflow: 'hidden' }}
                      onClick={() => { setImageZoom(!imageZoom); setImageUrlZoom(imagePreviews[index]) }}
                    >

                      <img style={{ width: '100%', height: '100%', objectFit: 'cover', margin: '10px 0px', borderRadius: '15px' }} src={imagePreviews[index]} alt="" />
                    </div>
                    }
                  </div>
                </div>
                {/* {imagePreviews[index]} */}
                <div className="preview_wrapper">
                </div>

                <div className="bot-msg-bubble">
                  <div className="bot-msg-bubble-container">
                    <div className="play-button-container">
                      {isVoiceSynthesisInProgress && (
                        <div className="play_loading_wrapper">
                          <div className="play_loading_container">
                            <PlayLoading />
                          </div>
                        </div>
                      )}
                      {!isVoiceSynthesisInProgress && // Check if audio synthesis is not in progress
                        (currentlyPlayingIndex === index ? (
                          <button
                            type="button"
                            onClick={() => { handleStopButtonClick(); setAudioVideoChange(false); }}
                            className={`stop-button ${entry.response ? "" : "hidden"
                              }`}
                          >
                            <img
                              src={stop}
                              height={20}
                              width={20}
                              alt="Knightingale said"
                              className="stop-icon"
                            />
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => playAudio(entry.response, index)}
                            className={`play-audio-button ${entry.response ? "" : "hidden"
                              }`}
                          >
                            {currentlyPlayingIndex !== index ? (
                              <img
                                src={play}
                                height={30}
                                width={30}
                                alt="Knightingale says"
                                className="play-icon"
                              />
                            ) : null}
                          </button>
                        ))}

                      <div key={index} className="play-button-menu" ref={addDotMenuRef(index)} onClick={() => { handleButtonClick(index) }} style={{
                        backgroundColor:
                          openMenuIndex === index ? "#0000004a" : "",
                      }} >
                        <img src={dot_menu} alt="dot_menu" />
                        {openMenuIndex === index && (<div>
                          <div ref={menuRef} className="dotmenu-wrapper">
                            <div className="dotmenu-container">
                              <div className="dotmenu-menu" onClick={() => {
                                handleCopyClick(textRefs[index], setShowOriginal)();
                              }}>
                                <img src={copy} alt="dot_menu" />
                                Copy Text
                              </div>
                              <div className="dotmenu-menu" onClick={() => setShowShareWindow(true)}>
                                <img src={share_line} alt="dot_menu" />
                                Share Thread
                              </div>
                            </div>
                          </div>
                        </div>)
                        }
                      </div>
                    </div>
                    <div className="bot-msg-text" ref={textRefs[index]}>
                      <p
                        dangerouslySetInnerHTML={{ __html: entry.response }}
                        className="answer-for-questions"
                        id="answer"
                        onChange={handleTextChange}
                      ></p>
                    </div>

                  </div>
                  {/* <div
                  className="copy"
                  onClick={() => {
                    handleCopyClick(textRefs[index], setShowOriginal)();
                    clickOK(index);
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "35px",
                      height: "35px",
                      borderRadiu: "15px",
                    }}
                    id={index}
                    className="copy-img copytool"
                    onMouseEnter={() => handleMouseEnterCopy(index)}
                    onMouseLeave={handleMouseLeaveCopy}
                  >

                    {isOk !== index && <img
                      id={index}
                      src={copy}
                      alt={showOriginal ? "Original Image" : "Changed Image"}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        opacity: 0.5,

                      }}
                      className="copy-img-hover"
                    />}

                    {isOk === index && <img
                      id={index}
                      src={okicon}
                      alt={showOriginal ? "Original Image" : "Changed Image"}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        // opacity: showOriginal ? 1 : 0,
                        // transition: "opacity 2s ease",
                      }}
                    />}

                    {showTooltip && hoveredIndex === index ? (<div style={{ color: '#000', }}>
                      <div class="">
                        <span class="copytooltext">Copy</span>
                      </div>
                    </div>) : (null)}
                  </div>
                </div> */}
                </div>
              </div>
            ))}
            {showLoadingIndicator && (
              <div className="main-q-a-box loading-indicator">
                <div className="client-msg-bubble left-client-msg-bubble">
                  <div className="client-questions bacground-client-msg-bubble">
                    <div className="loading-area">
                      <div className="loader">
                        <div className="loading-model">
                          <img src={loadingModel} alt="Knightingale Typing" />
                        </div>
                        <div
                          className={`loading-text loading-text-animate loadedAnimate`}
                        >
                          <span className="element-to-move typewriter-text">
                            Knightingale is typing
                          </span>
                        </div>
                        <div className="loading-sphere"></div>
                        <div className="loading-sphere"></div>
                        <div className="loading-sphere"></div>
                        {/* <div className="loading-sphere"></div>
                 <div className="loading-sphere"></div>
                 <div className="loading-sphere"></div>
                 <div className="loading-sphere"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </pre>
        </div>
        {imageZoom &&

          <div className="image_zoom_cover">
            <div className="image_zoom_image" onClick={() => { setImageZoom(false) }}>
              <img src={close_icon} alt="close_icon" className="image_zoom_img" />
            </div>
            <div className="image_zoom_wrapper">

              <img style={{}} src={imageUrlZoom} alt="" />
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default Chat;
