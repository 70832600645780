import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import userLogo from "../../img/profile/user.png";
import knightingaleLogo from "../../img/logo/knightingale_logo.png";
import shareIcon from "../../img/logo/knightingale_heart_logo.png";
import "./ShareComponent.css";
// import knightingale_character from "../../videos/chat/knightingale_character_loop.mp4";
import user from "../../img/knightingale_profile_image.png";
import { useShareWindow } from "../../context/ShareWindowContext.js";
import PlayLoading from "../../img/chat/playloading.js";
import stop from "../../img/chat/stop_button.svg";
import loadingModel from "../../img/knightingale_typing.png";
import play from "../../img/chat/listen_button.svg";
import knightingale_background_video from "../../videos/chat/idle.mp4";
import knightingale_background_video_mobile from "../../videos/chat/idle.mp4";
import knightingale_background_video_mobile_poster from "../../img/chat/knightingale_background_video_mobile_poster.jpg";
import knightingale_character_loop from "../../videos/chat/idle.mp4";
import careReminderMp4 from "../../videos/chat/idle.mp4";
// import knightingale_character_loop from "../../videos/chat/idle.mp4";

import careReminderMp4t from "../../videos/chat/lip_synced.mp4";
import knightingale_character_loop_poster from "../../img/chat/knightingale_character_loop_poster.jpg";
import knightingale_character_loop_postert from "../../img/chat/knightingale_character_loop_postert.jpg";

const AUDIO_SYNTHESIS_API_URL =
  "https://backend.knightingale.ai/api/convert-text-to-speech";
const SHARE = "https://backend.knightingale.ai/api/conversation-history";

const ShareComponent = () => {
  const { session } = useParams();
  const [conversationHistory, setConversationHistory] = useState([]);
  const {
    backgroundText, backgroundImg, backgroundreturnColor, currentlyPlayingIndex, setCurrentlyPlayingIndex,
    showShareWindow, setShowShareWindow, chatSession, setChatSession,
    generatedLink, setGeneratedLink, isVoiceSynthesisInProgress, setIsVoiceSynthesisInProgress,
    isAutoPlayInProgress, setIsAutoPlayInProgress, showStopButton, setShowStopButton, isSubmitting, setIsSubmitting,
    currentAudio, setCurrentAudio, disableSendButton, setDisableSendButton,
    currentlyPlayingAudio, setCurrentlyPlayingAudio, disableMicButton, setDisableMicButton, isVoiceSynthesisActive,
    audio, setAudio, setIsVoiceSynthesisActive, isAudioPlaying, setIsAudioPlaying, handleStopButtonClick,
    audioData, setAudioData, playAudio, textResponse, setTextResponse, userHistory, setUserHistory, showDropdown, setShowDropdown, audioVideoChange
  } = useShareWindow();
  const axiosInstance = axios.create();
  const axiosVoiceRecognitionInstance = axios.create();


  useEffect(() => {
    const fetchConversationHistory = async () => {
      try {
        const response = await axios.get(SHARE + `/${session}`);
        setConversationHistory(response.data);
      } catch (error) {
        console.error("Error fetching conversation history:", error);
      }
    };

    fetchConversationHistory();
  }, [session]);

  const triggerVoiceSynthesis = async (textResponse, isAutoPlay) => {
    try {
      setIsVoiceSynthesisInProgress(true);

      const audioRes = await axiosInstance.post(AUDIO_SYNTHESIS_API_URL, {
        text: textResponse,
      });

      // Save the audio URL for later playback along with the corresponding text
      const audioUrl = audioRes.data.audioUrl;
      setAudioData((prevAudioData) => ({
        ...prevAudioData,
        [textResponse]: audioUrl,
      }));

      setIsVoiceSynthesisInProgress(false);
    } catch (error) {
      console.error(error);
      setIsVoiceSynthesisInProgress(false);
    }
  };


  useEffect(() => {
    // adjustTextareaHeight();
    window.addEventListener("click", handleWindowClick);

    const playResponseVoice = async () => {
      try {
        if (textResponse) {
          // Play the audio URL if it exists
          if (audioData[textResponse]) {
            /*const newIndex = userHistory.length; // Get the current index
            setCurrentlyPlayingIndex(newIndex); // Set currentlyPlayingIndex
            const audioElement = new Audio(audioData[textResponse]);
            setCurrentAudio(audioElement); // Set currentAudio
            setIsAudioPlaying(true); // Set isAudioPlaying to true
            setIsAutoPlayInProgress(true); // Set auto-play in progress
            audioElement.play(); // Play the audio
            audioElement.onended = () => {
              setIsAutoPlayInProgress(false); // Reset auto-play in progress when done
              setIsAudioPlaying(false);
            };*/
          } else {
            await triggerVoiceSynthesis(textResponse, true);
            setIsAudioPlaying(true);
            const currentlyPlayingAudio = userHistory.length;
            setCurrentlyPlayingIndex(currentlyPlayingAudio);
            setIsAutoPlayInProgress(false); // Reset auto-play in progress
            setIsAudioPlaying(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    playResponseVoice();

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [textResponse, audioData, userHistory]);


  const handleWindowClick = () => {
    setShowDropdown(false);
  };
  return (
    <div className="share-wrapper">
      <div className="title-container">
        <img src={knightingaleLogo} alt="Share Icon" className="share-icon" />
      </div>
      <div className="share-middle-container-wrapper">
        <div className="share-container">


          <div className="share_middle_wrapper">
            <div className="share_container">
              <div className="share_knightigale_wrapper">

                <div className="desk_home_video_wrapper_share " style={{ width: '100%' }}>
                  <div className="desk_home_video_container_share">
                    <div className="new-background-video-container-chat videoclass start_videoclass">
                      <div
                        style={{
                          position: `${audioVideoChange ? 'static' : 'absolute'}`,
                          width: '100%'

                        }}
                      >

                        <video
                          className={`new-background-video-container-chat-video `}
                          style={{
                            // opacity: `${audioVideoChange ? '0' : '1'}`, left: '-55%',
                            // zIndex: '-10',
                            // position: `${audioVideoChange ? 'relative' : 'static'} `
                          }}
                          playsInline
                          autoPlay
                          loop
                          muted
                          poster={knightingale_character_loop_postert}
                          src={careReminderMp4}
                        />
                      </div>
                      <div
                        style={{
                          position: `${audioVideoChange ? 'absolute' : 'static'}`,
                          opacity: `${audioVideoChange ? '1' : '0'}`,
                          transition: 'opacity 200ms ease',
                          width: '100%'
                        }}
                      >

                        <video
                          className="new-background-video-container-chat-video"
                          style={{
                            // left: '-65%',
                            // zIndex: '-10',
                            // position: `${audioVideoChange ? 'relative' : 'static'} `, opacity: `${audioVideoChange ? '1' : '0'} `
                          }
                          }
                          laysInline
                          autoPlay
                          loop
                          muted
                          poster={knightingale_character_loop_poster}
                          src={careReminderMp4t}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="share_knightingale_conversation">

                <ul className="conversation-list">
                  <div className="types_wrappper">
                    <div className="types_container">
                      <div className="types_details">
                        <div className="types_details_title">A user shared their Knightingale health chat with you.</div>
                        <div className="types_details_date">
                          {conversationHistory.length > 0 && (
                            <p className="date">
                              {" "}
                              {new Date(conversationHistory[0].timestamp).toLocaleDateString()}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="types_option">
                        <div className="type_option_container">
                          <div className="type_option_inner" style={{ background: `${backgroundreturnColor}44`, border: `1px solid ${backgroundreturnColor}` }}>


                            <div className="type_option_img">
                              <img src={backgroundImg} alt={backgroundText} />
                            </div>
                            <div className="type_option_text">
                              {backgroundText}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {conversationHistory.map((item, index) => (
                    <li key={index} className="message-container">
                      <div className="user-message-container">
                        <div className="user-message">
                          <img src={userLogo} alt="User Logo" className="message-icon" />
                          <span className="title">User</span>
                          <p className="response client-question">{item.input}</p>
                        </div>
                      </div>
                      <div className="knightingale-message-container">
                        <div className="knightingale-message">
                          <div className="knightingale-message-details">
                            <img
                              src={user}
                              alt="Knightingale Logo"
                              className="message-icon"
                            />
                            <div className="title">Knightingale</div>
                          </div>

                          <div className="response bot-answer">
                            <div className="play-button-container">
                              {isVoiceSynthesisInProgress && (
                                <div className="play_loading_wrapper">
                                  <div className="play_loading_container">
                                    <PlayLoading />
                                  </div>
                                </div>
                              )}
                              {!isVoiceSynthesisInProgress && // Check if audio synthesis is not in progress
                                (currentlyPlayingIndex === index ? (
                                  <button
                                    type="button"
                                    onClick={handleStopButtonClick}
                                    className={`stop-button ${item.response ? "" : "hidden"
                                      }`}
                                  >
                                    <img
                                      src={stop}
                                      height={20}
                                      width={20}
                                      alt="Knightingale said"
                                      className="stop-icon"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => playAudio(item.response, index)}
                                    className={`play-audio-button ${item.response ? "" : "hidden"
                                      }`}
                                  >
                                    {currentlyPlayingIndex !== index ? (
                                      <img
                                        src={play}
                                        height={30}
                                        width={30}
                                        alt="Knightingale says"
                                        className="play-icon"
                                      />
                                    ) : null}
                                  </button>
                                ))}
                            </div>
                            <div>
                              {item.response}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

            </div>
          </div>
          <div className="share_footer">
            <div className="share_footer_btn_container">
              <div className="share_footer_btn">
                <a href="https://ask.knightingale.ai/">Get Started with Knightingale.AI</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareComponent;
